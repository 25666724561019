





































































































































































import { Mixin } from "@/core/mixins/mixin";
import { Component, Prop, Mixins } from "vue-property-decorator";
import { Row, Col, Image, Button, Dialog, Toast } from "vant";
import SelectCondition from "./SelectCondition.vue";
import wx from "weixin-js-sdk";
@Component({
  components: {
    [Dialog.Component.name]: Dialog.Component,
    Mixin,
    SelectCondition,
    [Row.name]: Row,
    [Col.name]: Col,
    [Image.name]: Image,
    [Button.name]: Button,
  },
})
export default class ActivityItem extends Mixins(Mixin) {
  @Prop({
    default: () => {
      return [];
    },
    type: Array,
  })
  public activityList!: Array<cms.ApplyActivityListDto>;
  @Prop({ default: false, type: Boolean }) public isAll!: boolean; //true:全部活动 false:已报名活动
  @Prop({ default: false, type: Boolean }) public tobePaid!: boolean; //是否为待支付
  ActTagsName(status: string): {
    name: string;
    color: string;
    bgColor: string;
  } {
    let obj = {
      name: "",
      bgColor: "",
      color: "",
    };
    if (this.isAll) {
      if (status === "ACTIVITY_APPLY_STATUS_ONWAY") {
        obj.name = "报名中";
        obj.bgColor = "#FF3E4E";
        obj.color = "#FFF";
      } else if (status === "ACTIVITY_APPLY_STATUS_COMING") {
        obj.name = "即将开始";
        obj.bgColor = "#6DD400";
        obj.color = "#FFF";
      } else if (status === "ACTIVITY_APPLY_STATUS_OVER") {
        obj.name = "报名截止";
        obj.bgColor = "#EEEEEE";
        obj.color = "#BBB";
      }
    } else {
      if (status === "1") {
        obj.name = "已入选";
        obj.bgColor = "#FFD948";
        obj.color = "#111111";
      } else if (status === "0") {
        obj.name = "等待审核";
        obj.bgColor = "#FFD948";
        obj.color = "#111111";
      } else if (status === "2") {
        obj.name = "未入选";
        obj.bgColor = "#eeeeee";
        obj.color = "#BBB";
      } else if (status === "3") {
        obj.name = "已取消";
        obj.bgColor = "#eeeeee";
        obj.color = "#BBB";
      }
    }
    return obj;
  }
  applyTypeArr = ["免费", "购票兑换", "付费", "积分兑换"];

  goActDetail(activityId: string): void {
    this.$emit("enterDetail", activityId);
  }
  checkEnter(activityId: string | undefined): void {
    this.$router.push(`/apply-list?activityId=${String(activityId)}`);
  }
  goEnterList(activityId: string | undefined): void {
    this.$router.push(`/apply-selected-list?activityId=${String(activityId)}`);
  }
  pay(item: any): void {
    // 调起支付
    if (item.applyType != "3") {
      this.$router.push(`/apply-success?activityId=${String(item.activityId)}`);
    } else if (item.orderId) {
      if (this.ChannelModule.channel === "plat_h5") {
        this.$router.replace({
          path: "/payconfirm",
          query: {
            orderId: `${item.orderId!}`,
            orderType: "ACTIVITYAPPLY",
            activityId: `${String(item.activityId)}`,
          },
        });
      } else if (this.ChannelModule.channel === "plat_wechat_miniapp") {
        // TODO 调起小程序支付
        let params = {
          actuallyPaidAmt: item.applyMoney,
          orderExpireTime: item.orderExpireTime,
          orderId: item.orderId,
          orderType: "ACTIVITYAPPLY",
        };
        wx.miniProgram.redirectTo({
          url: `/pagesOrder/confirmPayment/confirmPayment?par=${JSON.stringify(
            params
          )}`,
        });
      } else if (
        this.ChannelModule.channel === "plat_ios" ||
        this.ChannelModule.channel === "plat_android"
      ) {
        // 调起app支付
        this.jsBridge(
          "paymentActivity",
          `orderId/${item.orderId},actuallyPaidAmt/${
            item.applyMoney ? item.applyMoney.toFixed(2) : 0
          },orderExpireTime/${
            item.orderExpireTime
          },orderType/ACTIVITYAPPLY,activityId/${item.activityId}`
        );
      }
    }
  }
  /*
   *取消活动
   */
  payCancel(activityId: string | undefined): void {
    Dialog.confirm({
      message: "您确定取消报名吗？",
    })
      .then(() => {
        // monitorEvent("OrderDetail_ClickCancel", "点击取消订单"); // 埋点：订单详情，点击取消订单
        this.$toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
        });
        this.$api.oauth2Api.token.h5Token({ code: "" }, ({ data }) => {
          let code = data.code;
          Toast.loading({
            duration: 0, // 持续展示 toast
            forbidClick: true,
          });
          this.$api.cmsApi.applyActivity.cancelRegister(
            String(activityId),
            (data) => {
              Toast.clear();
              if (data.data) {
                this.$emit("changeButton", "4");
              }
            }
          );
        });
      })
      .catch(() => {
        // on cancel
      });
  }
  formDate(date: string): string {
    const dt = new Date(Number(date));
    const y = dt.getFullYear();
    const m = (dt.getMonth() + 1 + "").padStart(2, "0");
    const d = (dt.getDate() + "").padStart(2, "0");
    const hh = (dt.getHours() + "").padStart(2, "0");
    const mm = (dt.getMinutes() + "").padStart(2, "0");
    // const ss = (dt.getSeconds() + "").padStart(2, "0");

    return `${y}.${m}.${d} ${this.getWeek(dt)}  ${hh}:${mm}`;
  }
  getWeek(date: Date): string {
    let week;
    if (date.getDay() == 0) week = "星期日";
    if (date.getDay() == 1) week = "星期一";
    if (date.getDay() == 2) week = "星期二";
    if (date.getDay() == 3) week = "星期三";
    if (date.getDay() == 4) week = "星期四";
    if (date.getDay() == 5) week = "星期五";
    if (date.getDay() == 6) week = "星期六";
    return String(week);
  }
  trueCount(item: any): string {
    let count = 0;
    // 查看入选名单
    if (
      item.applyStatus === "ACTIVITY_APPLY_STATUS_OVER" &&
      (item.crtMemberApplyStatus == "1" || item.crtMemberApplyStatus == "2")
    ) {
      count++;
    }
    // 查看报名
    if (
      (item.applyIsCheck == "0" ||
        ((item.crtMemberApplyStatus == "1" ||
          item.crtMemberApplyStatus == "2") &&
          item.applyStatus !== "ACTIVITY_APPLY_STATUS_OVER")) &&
      !this.tobePaid
    ) {
      count++;
    }
    if (this.tobePaid) {
      count = count + 2;
    }
    return count.toString();
  }
}
