var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ActivityItem"},[_c('van-row',{staticClass:"activity-wrapper"},[_vm._l((_vm.activityList),function(item,index){return _c('van-col',{key:index,staticClass:"activity-item",class:{
          signUpActItem: !_vm.isAll,
          specialLine: Number(_vm.trueCount(item)) > 1,
        },on:{"click":function($event){_vm.goActDetail(String(item.activityId))}}},[_c('van-row',{staticClass:"left"},[(item.activityImageUrl)?_c('van-image',{staticClass:"act-img",attrs:{"fit":_vm.imageFit,"src":item.activityImageUrl},scopedSlots:_vm._u([{key:"error",fn:function(){return [_c('van-image',{attrs:{"fit":_vm.imageFit,"height":"100%","width":"100%","src":require('@/assets/images/placeholder/no-goods.png')}})]},proxy:true}],null,true)}):_vm._e(),(_vm.isAll)?_c('span',{staticClass:"act-tags",style:({
              backgroundColor: _vm.ActTagsName(String(item.applyStatus)).bgColor,
              color: _vm.ActTagsName(String(item.applyStatus)).color,
            })},[_vm._v(" "+_vm._s(_vm.ActTagsName(String(item.applyStatus)).name)+" ")]):_c('span',{staticClass:"act-tags",style:({
              backgroundColor: _vm.ActTagsName(String(item.crtMemberApplyStatus))
                .bgColor,
              color: _vm.ActTagsName(String(item.crtMemberApplyStatus)).color,
            })},[_vm._v(" "+_vm._s(_vm.ActTagsName(String(item.crtMemberApplyStatus)).name)+" ")])],1),_c('van-row',{staticClass:"right"},[_c('div',{staticClass:"desc-content"},[_c('p',{staticClass:"desc-act-name two-omit"},[_vm._v(" "+_vm._s(item.activityName)+" ")]),_c('van-row',{staticClass:"desc-center",class:{ signUpCenter: !_vm.isAll }},[_c('van-col',{staticClass:"addrTime"},[_c('span',{staticClass:"address"},[_vm._v(_vm._s(item.siteName))]),_c('p',{staticClass:"times"},[_vm._v(" "+_vm._s(_vm.formDate(String(item.activityStartTime)))+" ")])])],1),_c('van-col',{staticClass:"isfree"},[_vm._v(" "+_vm._s(item.applyType == "3" ? "¥" + item.applyMoney : item.applyType == "4" ? item.applyIntegral + "积分" : _vm.applyTypeArr[Number(item.applyType) - 1])+" ")]),(
                _vm.isAll && item.applyStatus === 'ACTIVITY_APPLY_STATUS_OVER'
              )?_c('van-button',{staticClass:"roster-btn disb-btn",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();_vm.goActDetail(String(item.activityId))}}},[_vm._v("报名结束 ")]):(_vm.isAll)?_c('van-button',{staticClass:"roster-btn apply-btn",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();_vm.goActDetail(String(item.activityId))}}},[_vm._v("立即报名 ")]):_vm._e()],1),(!_vm.isAll)?_c('div',{class:[
              'btn-group',
              _vm.trueCount(item) == 1 ? 'oneChild' : 'newLine' ]},[(
                (item.applyIsCheck == '0' ||
                  ((item.crtMemberApplyStatus == '1' ||
                    item.crtMemberApplyStatus == '2') &&
                    item.applyStatus !== 'ACTIVITY_APPLY_STATUS_OVER')) &&
                !_vm.tobePaid
              )?_c('van-button',{staticClass:"btn btn1",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.checkEnter(item.activityId)}}},[_vm._v("查看报名")]):_vm._e(),(_vm.tobePaid)?_c('van-button',{staticClass:"btn btn1 cancelBg",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.payCancel(item.activityId)}}},[_vm._v("取消报名 ")]):_vm._e(),(_vm.tobePaid)?_c('van-button',{staticClass:"btn btn1 apply-btn",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.pay(item)}}},[_vm._v("立即付款 ")]):_vm._e()],1):_vm._e()])],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }